import { createSelector } from 'reselect'
import { StoreReturnType } from '@store/store'

export const getAccountStatistics = (state: StoreReturnType) =>
  state.dashboardState.accounts

export const getTotalAssetStatistics = (state: StoreReturnType) =>
  state.dashboardState.totalAssetsNumber

export const getProtectedAssetStatistics = (state: StoreReturnType) =>
  state.dashboardState.protectedAssetsNumber

export const getProtectedAssetsPercentStatistics = (state: StoreReturnType) =>
  state.dashboardState.assetsUnderProtectionPercent

export const getRecoverabilityIndexStatistics = (state: StoreReturnType) =>
  state.dashboardState.recoverabilityIndex

export const getUnhealthyRPStatistics = (state: StoreReturnType) =>
  state.dashboardState.unhealthyRecoveryPoints

export const getTotalAssetErrorMessage = (state: StoreReturnType) =>
  state.dashboardState.totalAssetsErrorMessage

export const getProtectedAssetErrorMessage = (state: StoreReturnType) =>
  state.dashboardState.protectedAssetsErrorMessage

export const getProtectedAssetsPercentErrorMessage = (state: StoreReturnType) =>
  state.dashboardState.assetsUnderProtectionPercentErrorMessage

export const getRecoverabilityIndexErrorMessage = (state: StoreReturnType) =>
  state.dashboardState.recoverabilityIndexErrorMessage

export const getUnhealthyRPErrorMessage = (state: StoreReturnType) =>
  state.dashboardState.unhealthyRecoveryPointsErrorMessage

export const getRecoverabilityStatsErrorMessage = (state: StoreReturnType) =>
  state.dashboardState.recoverabilityStatsErrorMessage

export const getRecoverabilityStats = (state: StoreReturnType) =>
  state.dashboardState.recoverabilityStats

export const getCoveredByBackupPoliciesAssets = (state: StoreReturnType) =>
  state.dashboardState.coveredByBackupPoliciesAssets

export const getCoveredByBackupPoliciesAssetsErrorMessage = (
  state: StoreReturnType
) => state.dashboardState.coveredByBackupPoliciesAssetsErrorMessage

export const getBackupComplianceScore = (state: StoreReturnType) =>
  state.dashboardState.backupComplianceScore

export const getDashboardData = (state: StoreReturnType) =>
  state.dashboardState.dashboardData
export const selectDashboardData = createSelector(
  [getDashboardData],
  (dashboardData) => dashboardData
)

export const getSearchDashboard = (state: StoreReturnType) =>
  state.dashboardState.search

export const getEbsTableDashboardData = (state: StoreReturnType) =>
  state.dashboardState.tableVolumeWithSnapshotsData

export const getPossibleVolumeWithSnapshotsDashboardFilters = (
  state: StoreReturnType
) => state.dashboardState.possibleVolumeWithSnapshotsFilters

export const getSelectedVolumeWithSnapshotsDashboardFilters = (
  state: StoreReturnType
) => state.dashboardState.selectedVolumeWithSnapshotsFilters

export const getEbsInventoryTableDashboardData = (state: StoreReturnType) =>
  state.dashboardState.tableVolumeInventoryData

export const getPossibleVolumeInventoryDashboardFilters = (
  state: StoreReturnType
) => state.dashboardState.possibleVolumeInventoryFilters

export const getPossibleVolumeInventoryDashboardFiltersV3 = (
  state: StoreReturnType
) => state.dashboardState.possibleVolumeInventoryFiltersV3

export const getSelectedVolumeInventoryDashboardFilters = (
  state: StoreReturnType
) => state.dashboardState.selectedVolumeInventoryFilters

export const getSelectedVolumeInventoryDashboardFiltersV3 = (
  state: StoreReturnType
) => state.dashboardState.selectedVolumeInventoryFiltersV3

export const getVolumeInventorySearchDashboardV3 = (state: StoreReturnType) =>
  state.dashboardState.searchVolumeInventoryV3

export const getQuarantinedVolumesTableData = (state: StoreReturnType) =>
  state.dashboardState.tableQuarantinedVolumesData

export const getPossibleQuarantinedVolumesFilters = (state: StoreReturnType) =>
  state.dashboardState.possibleQuarantinedVolumesFilters

export const getSelectedQuarantinedVolumesDashboardFilters = (
  state: StoreReturnType
) => state.dashboardState.selectedQuarantinedVolumesFilters

export const getSnapshotVulnerabilitiesTableData = (state: StoreReturnType) =>
  state.dashboardState.tableSnapshotVulnerabilitiesData

export const getPossibleSnapshotVulnerabilitiesFilters = (
  state: StoreReturnType
) => state.dashboardState.possibleSnapshotVulnerabilitiesFilters

export const getSelectedSnapshotVulnerabilitiesFilters = (
  state: StoreReturnType
) => state.dashboardState.selectedSnapshotVulnerabilitiesFilters

export const getEbsVulnerabilitiesTableDashboardData = (
  state: StoreReturnType
) => state.dashboardState.tableEbsVulnerabilitiesData

export const getPossibleEbsVulnerabilitiesFilters = (state: StoreReturnType) =>
  state.dashboardState.possibleEbsVulnerabilitiesFilters

export const getSelectedEbsVulnerabilitiesFilters = (state: StoreReturnType) =>
  state.dashboardState.selectedEbsVulnerabilitiesFilters

export const getEc2InventoryTableDashboardData = (state: StoreReturnType) =>
  state.dashboardState.tableEc2InventoryData

export const getPossibleEc2InventoryFiltersV3 = (state: StoreReturnType) =>
  state.dashboardState.possibleEc2InventoryFiltersV3

export const getPossibleEc2InventoryFilters = (state: StoreReturnType) =>
  state.dashboardState.possibleEc2InventoryFilters

export const getSelectedEc2InventoryFilters = (state: StoreReturnType) =>
  state.dashboardState.selectedEc2InventoryFilters

export const getSelectedEc2InventoryFiltersV3 = (state: StoreReturnType) =>
  state.dashboardState.selectedEc2InventoryFiltersV3

export const getEc2nventorySearchDashboardV3 = (state: StoreReturnType) =>
  state.dashboardState.searchEc2InventoryV3

export const getPossibleInstancesThreatsFilters = (state: StoreReturnType) =>
  state.dashboardState.possibleInstancesThreatsFilters

export const getSelectedInstancesThreatsFilters = (state: StoreReturnType) =>
  state.dashboardState.selectedInstancesThreatsFilters

export const getPossibleActiveThreadsVolumesFilters = (
  state: StoreReturnType
) => state.dashboardState.possibleVolumesThreatsFilters

export const getSelectedActiveThreadsVolumesFilters = (
  state: StoreReturnType
) => state.dashboardState.selectedVolumesThreatsFilters

export const getPossibleActiveThreadsFilters = (state: StoreReturnType) =>
  state.dashboardState.possibleThreatsFilters

export const getInstancesActiveThreadsData = (state: StoreReturnType) =>
  state.dashboardState.tableInstancesThreatsData

export const getVolumesActiveThreadsData = (state: StoreReturnType) =>
  state.dashboardState.tableVolumesThreatsData

export const getEbsVulnerabilitiesData = (state: StoreReturnType) =>
  state.dashboardState.tableVolumesVulnerabilitiesData

export const getEbsVulnerabilitiesPossibleFilters = (state: StoreReturnType) =>
  state.dashboardState.possibleVolumesVulnerabilitiesFilters

export const getEbsVulnerabilitiesSelectedFilters = (state: StoreReturnType) =>
  state.dashboardState.selectedVolumesVulnerabilitiesFilters

export const getSnapshotsVulnerabilitiesData = (state: StoreReturnType) =>
  state.dashboardState.tableSnapshotsVulnerabilitiesData

export const getSnapshotsVulnerabilitiesPossibleFilters = (
  state: StoreReturnType
) => state.dashboardState.possibleSnapshotsVulnerabilitiesFilters

export const getSnapshotsVulnerabilitiesSelectedFilters = (
  state: StoreReturnType
) => state.dashboardState.selectedSnapshotsVulnerabilitiesFilters

export const getVolumesWithRecoveryExposureData = (state: StoreReturnType) =>
  state.dashboardState.tableVolumesExposureData

export const getVolumesWithRecoveryExposurePossibleFilters = (
  state: StoreReturnType
) => state.dashboardState.possibleVolumesExposureFilters

export const getVolumesWithRecoveryExposureSelectedFilters = (
  state: StoreReturnType
) => state.dashboardState.selectedVolumesExposureFilters

export const getPossibleS3BucketInventoryDashboardFiltersV3 = (
  state: StoreReturnType
) => state.dashboardState.possibleS3BucketInventoryFiltersV3

export const getSelectedS3BucketInventoryDashboardFiltersV3 = (
  state: StoreReturnType
) => state.dashboardState.selectedS3BucketInventoryFiltersV3
